export default function DateToDate(number) {
  let dia;
  let mes;
  let ano;

  dia = String(number).substring(8, 10);
  mes = String(number).substring(5, 7);
  ano = String(number).substring(0, 4);

  return dia + '/' + mes + '/' + ano;
}
