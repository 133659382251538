<template>
  <div class="sells q-px-md">

    <!-- QUADRO RESUMO -->
    <div class="fluxo responsive full-width q-mt-md" style="height: 100%;">
      <div class="box bg-verde">
        <div class="title bLTR text-h6 text-white">
          Resumo das OS's
        </div>
        <div class="row bg-verde bA" style="height: 100%;">
          <div class="adquirida col-6">
            
            <q-card square class="adquirida">
              <div class="title row bB justify-center bg-verdeclaro">
                <div v-if="isMobile" class="text text-subtitle1 text-bold">
                  Pendente
                </div>
                <div v-else class="text text-subtitle1 text-bold">
                  OS Pendente
                </div>
              </div>
              <div class="body column q-py-lg">
                <div class="valor text-verde text-bold">
                    {{ os_pendente }}
                </div>
              </div>
            </q-card>

          </div>
          <div class="vendida col-6">

            <q-card square class="vendida">
              <div class="title row bB justify-center bg-verdeclaro">
                <div v-if="isMobile" class="text text-subtitle1 text-bold">
                  Finalizada
                </div>
                <div v-else class="text text-subtitle1 text-bold">
                  OS Finalizada
                </div>
              </div>
              <div class="body column q-py-lg">
                <div class="valor text-verde text-bold">
                    {{ os_finalizada }}
                </div>
              </div>
            </q-card>

          </div>
        </div>
      </div>
    </div>

    <!-- FAIXA DE CONTROLE -->
    <div class="btns q-mt-md row justify-center">
      <q-btn no-caps icon="handyman" label="Novo Registro" @click="onAdd" style="width: 280px; background-color: var(--primary)" class="q-mb-md q-mx-md text-white" />
    </div>

    <!-- TABELA DE ACOMPANHAMENTO -->
    <div class="table">
      <q-table
        dense
        class="myTable"
        :rows="rows"
        :columns="cols"
        row-key="name"
        :rows-per-page-options="[10, 20, 50, 100, 99999]"
        rows-per-page-label="Linhas por página: "
        :filter="filter"
        no-results-label="Sem dados a serem mostrados"
        no-data-label="Sem dados a serem mostrados"
        @row-click="onEditRow"
      >
        <template v-slot:top-left>
          <q-input filled dense dark v-model="filter" placeholder="Pesquisar" style="width: 150px">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
        <template v-slot:top-right>
          <q-btn
            flat
            rounded
            style="background-color: var(--verdeclaro); color: white"
            icon="file_download"
            @click="exportTable(rows)"
          />
        </template>
      </q-table>
    </div>

    <!-- MODAL PARA INSERÇÃO DE NOVOS REGISTROS -->
    <q-dialog v-model="modalOS" full-width persistent>
      <div class="bg-white q-pa-lg q-gutter-y-sm">
        <div v-if="editRow" class="title text-h5 text-bold text-center text-verde q-mb-md">
          EDITANDO ORDEM DE SERVIÇO
        </div>
        <div v-else class="title text-h5 text-bold text-center text-verde q-mb-md">
          INCLUSÃO DE NOVA ORDEM DE SERVIÇO
        </div>

        <q-separator spaced size="5px" style="background-color: var(--primary)" />
        
        <q-scroll-area style="width: 100%; height: 60vh;" v-show="rebuild">

          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="fourFields" outlined v-model="dt_os" type="date" label="Data" stack-label />
            <q-select class="fourFields q-mx-md" v-if="!isMobile" outlined v-model="status_os" :options="['Pendente', 'Finalizada']" label="Status" stack-label style="width: 100%" />
            <q-select class="fourFields" v-else outlined v-model="status_os" :options="['Pendente', 'Finalizada']" label="Status" stack-label style="width: 100%" />
            <q-input class="fourFields" outlined v-model="num_os" type="text" label="Número OS" stack-label disable />
          </div>

          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="twoFields" outlined v-model="client_os" type="text" label="Nome do Cliente" stack-label />
            <q-input class="twoFields" outlined v-model="tel_os" type="text" label="Telefone Contato" stack-label />
          </div>

          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="twoFields" outlined v-model="end_os" type="text" label="Endereço Completo" stack-label />
            <q-input class="twoFields" outlined v-model="gar_os" type="text" label="Garantia Serviço" stack-label />
          </div>

          <div v-for="n in countOS" :key="n" class="respRowForm q-pa-sm q-my-sm">
            <q-input class="threeFields" dense v-model="prod_os[n]" type="text" label="Descrição Produto" standout="text-yellow" stack-label />
            <q-input class="threeFields" dense v-model="def_os[n]" type="text" label="Defeito" standout="text-yellow" stack-label />
            <q-input class="threeFields" dense v-model="val_os[n]" @blur="onChangeVal" type="number" label="Valor" standout="text-yellow" stack-label />
          </div>

          <div class="row justify-between q-mt-sm">
            <q-btn flat no-caps color="info" label="Adicionar Linha" v-model="countOS" @click="countOS++" />
            <div class="text-h6 text-grey q-mr-sm">{{ `R$ ${valorTotalOS}` }}</div>
          </div>

          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="oneFields" outlined v-model="obs_os" type="textarea" label="Observação" stack-label autogrow />
          </div>

        </q-scroll-area>

        <div class="btns row justify-center q-pt-md">
          <q-btn v-show="editRow" outline no-caps color="warning" label="Gerar O.S." @click="onCreateOS" />
          <div class="q-mx-md" />
          <q-btn outline no-caps color="negative" label="Cancelar" @click="onCancel" />
          <div class="q-mx-md" />
          <q-btn outline no-caps color="primary" label="Salvar" @click="onSave" />
          <div class="q-mx-md" />
          <q-btn v-show="editRow" no-caps color="negative" label="Excluir" @click="onDelete" />
        </div>
      </div>
    </q-dialog>

    <!-- MODAL DO COMPROVANTE -->
    <q-dialog v-model="modalComprovante" persistent full-width>
      <div class="bg-white q-pa-lg q-gutter-y-sm">
        <div class="title text-h5 text-bold text-center text-verde q-mb-md">
          EMITIR ORDEM DE SERVIÇO
        </div>

        <q-separator spaced size="5px" style="background-color: var(--primary)" />

        <q-scroll-area class="bg-grey" style="width: 100%; height: 60vh;">
          <div class="q-mx-xl q-my-lg">
            <div class="bg-white" id="ordemServico">
              <div class="row justify-center items-center q-mx-xl q-py-md">
                <div class="row justify-center text-h4 text-bold text-italic">
                  {{ enterprise }}
                </div>
              </div>
              <div>
                <div class="gradeTable row justify-between q-mx-xl">
                  <div class="row col-4">
                    <div class="col-6 bg-verde  text-white q-pl-md">Nº Ordem</div>
                    <div class="col-6 q-pl-md">{{ num_os }}</div>
                  </div>
                  <div class="row col-4">
                    <div class="col-6 bg-verde text-white q-pl-md">Data Emissão</div>
                    <div class="col-6 q-pl-md">{{ dateOS }}</div>
                  </div>
                  <div class="row col-4">
                    <div class="col-6 bg-verde text-white q-pl-md">Telefone Contato</div>
                    <div class="col-6 q-pl-md">{{ tel_os }}</div>
                  </div>
                </div>
                <div class="gradeTableLR row q-mx-xl">
                  <div class="row col-8">
                    <div class="col-3 bg-verde text-white q-pl-md">Nome Cliente</div>
                    <div class="col-9 q-pl-md">{{ client_os }}</div>
                  </div>
                  <div class="row col-4">
                    <div class="col-6 bg-verde text-white q-pl-md">Garantia</div>
                    <div class="col-6 q-pl-md">{{ gar_os }}</div>
                  </div>
                </div>
                <div class="gradeTable row q-mx-xl">
                  <div class="col-2 bg-verde text-white q-pl-md">Endereço</div>
                  <div class="col-10 q-pl-md">{{ end_os }}</div>
                </div>
              </div>
              
              <div class="text-h5 text-bold text-center q-my-lg">Ordem de Serviço</div>

              <div class="q-pb-xl">
                <div class="row justify-center items-center">
                  <div class="gradeTableC col-2 bg-verde text-center text-white text-bold text">ID</div>
                  <div class="gradeTableC col-1 bg-verde text-center text-white text-bold">Produto</div>
                  <div class="gradeTableC col-4 bg-verde text-center text-white text-bold">Defeito</div>
                  <div class="gradeTableC col-2 bg-verde text-center text-white text-bold">Val. Unitário</div>
                </div>
                <div class="row justify-center" v-for="(row, index) in items" :key="index">
                  <div class="gradeTableC row items-center justify-center col-1" style="height: 50px">{{ index + 1 }}</div>
                  <div class="gradeTableC row items-center justify-center col-4" style="height: 50px">{{ row.description }}</div>
                  <div class="gradeTableC row items-center justify-center col-2" style="height: 50px">{{ row.bug }}</div>
                  <div class="gradeTableC row items-center justify-center col-2" style="height: 50px">{{ `R$ ${parseFloat(row.price).toFixed(2)}` }}</div>
                </div>

                <div class="row justify-center">
                  <div class="gradeTableC row items-center justify-center col-9" style="height: 35px" />
                  <div class="gradeTableC row items-center justify-center col-9" style="height: 35px" />
                  <div class="row items-center justify-center col-9" style="height: 35px" />
                </div>

                <div class="row justify-center full-width text-subtitle1 text-bold">
                  <div class="col-9 row gradeTableTB">
                    <div class="col-8 bg-verde text-center text-white q-pl-md">Valor Total</div>
                    <div class="col-4 text-center q-pl-md">{{ `R$ ${valorTotalOS}` }}</div>
                  </div>
                </div>
                <div>
                  <div class="q-mt-md" >
                    <div class="bg-verde text-white text-bold text-center q-mx-xl">Observações</div>
                    <q-input outlined class="q-mx-xl" v-model="obs_os" type="textarea" autogrow />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </q-scroll-area>

        <div class="btns row justify-center q-pt-md">
          <q-btn outline no-caps color="negative" label="Fechar" @click="modalComprovante = false" />
          <div class="q-mx-md" />
          <q-btn outline no-caps color="green" label="Guardar Comprovante" @click="onSaveComprovante" />
        </div>
      </div>
    </q-dialog>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Dialog } from 'quasar';
import { defaultColor } from '../../helpers/defaultColor';
import DateToDate from '../../helpers/DatetoDate';
import { supabase } from '../../supabase';
import * as htmlToImage from 'html-to-image';

export default {
  data() {
    return {
      enterprise: localStorage.getItem('enterprise'),
      modalOS: false,
      modalComprovante: false,
      filter: null,
      cols: [
        { name: 'num',    align: 'center', label: 'Núm. OS',         field: 'num',     sortable: true,
          format: val => String('0').repeat(8 - String(val).length) + val
        },
        { name: 'dt',     align: 'center', label: 'Data Entrada',           field: 'dt',      sortable: true,
          format: val => DateToDate(val)
        },
        { name: 'status',        align: 'center', label: 'Status',             field: 'status',         sortable: true },
        { name: 'client',          align: 'center', label: 'Cliente',               field: 'client',           sortable: true },
        { name: 'phone',      align: 'center', label: 'Telefone',     field: 'phone',       sortable: true },
        { name: 'address',align: 'center', label: 'Endereço',  field: 'address', sortable: true },
        { name: 'warranty',  align: 'center', label: 'Garantia',    field: 'warranty',   sortable: true },
        { name: 'obs',        align: 'center', label: 'Observação', field: 'obs',         sortable: true },
        { name: 'description',        align: 'center', label: 'Descrição', field: 'description',         sortable: true },
        { name: 'bug',        align: 'center', label: 'Defeito', field: 'bug',         sortable: true },
        { name: 'price',        align: 'center', label: 'Preço', field: 'price',         sortable: true },
      ],
      rows: [],

      //~> PARA O MODAL
      dt_os: Date.now(),
      num_os: null,
      client_os: null,
      tel_os: null,
      end_os: null,
      gar_os: null,
      prod_os: [],
      def_os: [],
      val_os: [],
      items: [],
      valorTotalOS: 0,
      obs_os: null,
      status_os: null,
      countOS: 1,
      dateOS: null,
      rebuild: true,
      os_pendente: 0,
      os_finalizada: 0,

      //~> CONTROLA GRAVAÇÃO vs EDIÇÃO
      editRow: false,
    }
  },
  computed: {
    isMobile() {
      return (this.$q.screen.sm || this.$q.screen.xs);
    },
  },
  methods: {
    ...mapActions('Functions', [ 'rpc' ]),
    ...mapActions('Querys', [ 'insertData', 'updateData' ]),
    resetForm() { //~> OK
      //~> Fazendo RESET caso o usuário cancele ou entra para preencher o Form
      this.dt_os = Date.now()
      this.num_os = ''
      this.client_os = ''
      this.tel_os = ''
      this.end_os = ''
      this.gar_os = ''
      this.prod_os = []
      this.def_os = []
      this.val_os = []
      this.obs_os = ''
      this.status_os = ''
      this.countOS = 1
      this.valorTotalOS = 0
    },
    validFields() {
      if(
        this.category == '' || this.category == null ||
        this.product == '' || this.product == null ||
        this.brand == '' || this.brand == null ||
        this.color == '' || this.color == null ||
        this.sizes == '' || this.sizes == null
      ) {
        this.statusFields = false;
        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: 'Favor preencher todos os campos'
        });
      } else {
        this.statusFields = true;
      }
    },
    async onAdd() { //~> OK
      this.resetForm();
      this.modalOS = true;

      if(!this.editRow) {
        const os = await this.rpc({
          function: 'numos'
        })
        this.num_os = String('0').repeat(8 - String(os[0].numos).length) + os[0].numos;
      }
    },
    onCancel() { //~> OK
      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Deseja realmente sair? Ao sair o preenchimento será perdido.',
        persistent: true,
        cancel: true,
      }).onOk(() => {
        this.resetForm();
        this.editRow = false;
        this.modalOS = false;
      })
    },
    async onSave() { //~> OK

      var items = []
      this.prod_os.forEach((doc, idx) => {
          var item = {}

          item.description = doc
          item.bug = this.def_os[idx]
          item.price = parseFloat(this.val_os[idx])
          
          items.push(item)
      })

      try {
        
        if(this.editRow) {
          await this.updateData({
            table: 'GE_REPAIR',
            fields: {
              'GE_DATE': this.dt_os,
              'GE_STATUS': this.status_os,
              'GE_CLIENT': this.client_os,
              'GE_PHONE': this.tel_os,
              'GE_ADDRESS': this.end_os,
              'GE_WARRANTY': this.gar_os,
              'GE_OBS': this.obs_os,
              'GE_ITEMS': items
            },
            match: {
              'RECNO': this.num_os
            }
          })

          Dialog.create({
            title: 'Gestão Eletrônicos',
            message: 'Registro editado com sucesso!'
          });
        } else {
          await this.insertData({
            table: 'GE_REPAIR',
            fields: {
              'GE_DATE': this.dt_os,
              'GE_STATUS': this.status_os,
              'GE_CLIENT': this.client_os,
              'GE_PHONE': this.tel_os,
              'GE_ADDRESS': this.end_os,
              'GE_WARRANTY': this.gar_os,
              'GE_OBS': this.obs_os,
              'GE_ITEMS': items,
              'GE_ENTERPRISE': this.enterprise,
              'DELETED': ' '
            }
          })

          Dialog.create({
            title: 'Gestão Eletrônicos',
            message: 'Registro adicionado com sucesso!'
          });
        }

        this.resetForm();
        this.modalOS = false;
        this.editRow = false;
        this.executeQuery();
      } catch (error) {
        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: error.message
        });
      }
    },
    onDelete() { //~> OK
      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Deseja realmente <span class="text-negative text-bold">EXCLUIR</span> o registro?',
        html: true,
        persistent: true,
        cancel: true,
      }).onOk(async () => {
        //NUNCA EXCLUIREMOS UM REGISTRO, APENAS MARCAMOS NO CAMPO DELETED COMO '*'
          await this.updateData({
            table: 'GE_REPAIR',
            fields: {
              'DELETED': '*'
            },
            match: {
              'RECNO': this.num_os
            }
          })

        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: 'Registro excluído com sucesso!'
        });

        this.modalOS = false;
        this.editRow = false;
        this.executeQuery();
      })
    },
    onCreateOS() { //~> OK
      this.modalComprovante = true;
    },
    async executeQuery() { //~> OK
      //~> PARA ALIMENTAR A TABELA
      let repair = await supabase
        .from('GE_REPAIR')
        .select('*')
        .eq('GE_ENTERPRISE', this.enterprise)
        .filter('DELETED', 'eq', ' ')

      var pendente = 0
      var finalizada = 0
      this.rows = repair.data.map((rep) => {
        rep.GE_STATUS === 'Pendente'
          ? pendente += 1
          : finalizada += 1

        let descricao = ''
        let defeito = ''
        let preco = 0

        rep.GE_ITEMS.forEach((item, index) => {
          descricao = (index < rep.GE_ITEMS.length - 1)
            ? descricao + item.description + '; '
            : descricao + item.description
          return descricao
        })
        rep.GE_ITEMS.forEach((item, index) => {
          defeito = (index < rep.GE_ITEMS.length - 1)
            ? defeito + item.bug + '; '
            : defeito + item.bug
          return defeito
        })
        rep.GE_ITEMS.forEach((item, index) => {
          preco = (index < rep.GE_ITEMS.length - 1)
            ? preco + item.price + '; '
            : preco + item.price
          return preco
        })

        return {
          num: rep.RECNO,
          dt: rep.GE_DATE,
          status: rep.GE_STATUS,
          client: rep.GE_CLIENT,
          phone: rep.GE_PHONE,
          address: rep.GE_ADDRESS,
          warranty: rep.GE_WARRANTY,
          obs: rep.GE_OBS,
          description: descricao,
          bug: defeito,
          price: preco
        }
      });

      this.os_pendente = pendente
      this.os_finalizada = finalizada

    },
    onChangeVal() { //~> OK
      var subtotal = 0
      this.val_os.forEach((val, i) => {
        this.val_os[i] = parseFloat(val).toFixed(2)
        subtotal += parseFloat(val)
      })

      this.valorTotalOS = parseFloat(subtotal).toFixed(2)
    },
    onEditRow(_, row) { //~> OK
      this.dt_os = row.dt
      this.num_os = String('0').repeat(8 - String(row.num).length) + row.num
      this.status_os = row.status
      this.client_os = row.client
      this.tel_os = row.phone
      this.end_os = row.address
      this.gar_os = row.warranty
      this.obs_os = row.obs

      let descricao = []
      let defeito = []
      let valor = []

      var desc = String(row.description).split('; ')
      this.countOS = desc.length
      desc.forEach(d => {
        descricao.push(d)
      })
      var bug = String(row.bug).split('; ')
      bug.forEach(b => {
        defeito.push(b)
      })
      var preco = String(row.price).split('; ')
      var total = 0
      preco.forEach(p => {
        total += parseFloat(p)
        valor.push(p)
      })

      for(let i = 1; i <= this.countOS; i++) {
        this.prod_os[i] = descricao[i-1]
        this.def_os[i] = defeito[i-1]
        this.val_os[i] = parseFloat(valor[i-1]).toFixed(2)
      }

      this.valorTotalOS = parseFloat(total).toFixed(2)
      this.dateOS = DateToDate(this.dt_os)
      
      var items = []
      desc.forEach((doc, idx) => {
          var item = {}

          item.description = doc
          item.bug = this.def_os[idx + 1]
          item.price = parseFloat(this.val_os[idx + 1])
          
          items.push(item)
      })

      this.items = items;
      console.log(this.items);

      this.editRow = true;
      this.modalOS = true;
    },
    exportTable(aTable) { //~> OK
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(aTable[0]).join(";"),
        ...aTable.map(item => Object.values(item).join(";"))
        ].join("\n")
        .replace(/(^\[)|(\]$)/gm, "")
        .replaceAll("ç", "c")
        .replaceAll("ã", "a")
        .replaceAll("â", "a")
        .replaceAll("á", "a")
        .replaceAll("à", "a")
        .replaceAll("ê", "e")
        .replaceAll("é", "e")
        .replaceAll("í", "i")
        .replaceAll("õ", "o")
        .replaceAll("ô", "o")
        .replaceAll("ú", "u");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "gestao_Estoque.csv");
      link.click();
    },
    onSaveComprovante() { //~> OK
      htmlToImage.toPng(
        document.querySelector('#ordemServico'),
        { quality: 1 }
      ).then(dataURL => {
          var link = document.createElement('a');
          link.download = `OS - ${this.client_os}.png`;
          link.href = dataURL;
          link.click();
        }
      )
    }
  },
  created() {
    defaultColor();
    this.executeQuery();
  }
}
</script>

<style lang="scss">
  @import url('../../globais/GlobalStyles.scss');
</style>